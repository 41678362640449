<template>
    <div
        v-editable="item.vEditable"
        class="group flex aspect-square max-h-[240px] flex-col rounded-xl bg-white text-white drop-shadow-[0_0_8px_rgba(20,20,20,0.1)]"
    >
        <div class="relative h-full w-full">
            <StoryblokImage
                :src="item.image.src"
                :alt="item.image.alt"
                class="h-full w-full rounded-lg bg-white object-contain"
                sizes="180px sm:240px"
            />
            <div
                v-if="item.showHoverState"
                class="absolute left-0 top-0 z-10 h-full w-full rounded-xl bg-brew p-6 text-white opacity-0 transition-opacity group-hover:opacity-100"
            >
                <div class="mb-2 truncate text-[12px] font-bold">
                    {{ item.hoverTagTitle }}
                </div>
                <div class="mb-2 truncate text-[20px] font-bold">
                    {{ item.hoverTitle }}
                </div>
                <WysiwygContent
                    v-if="item.hoverContent"
                    :content="truncatedContent"
                    class="mb-4 h-[90px] text-[14px]"
                />
                <NuxtLink v-if="item.hoverLink" :to="item.hoverLink.url">
                    <span class="text-[14px]">{{
                        item.hoverLink.label
                    }}</span></NuxtLink
                >
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ImageGridItemType from '@types/ImageGridItemType';

const props = defineProps<{
    item: ImageGridItemType;
}>();

const truncatedContent = computed<string | null>(() => {
    const length = 110;
    const ending = '...';
    if (props.item.hoverContent && props.item.hoverContent.length > length) {
        return (
            props.item.hoverContent.substring(0, length - ending.length) +
            ending
        );
    }
    return props.item.hoverContent ? props.item.hoverContent : null;
});
</script>
